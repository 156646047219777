import 'simpledotcss/simple.min.css'
import './styles/index.sass'

import React from 'react'
import { RecoilRoot } from 'recoil'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import mixpanel from 'mixpanel-browser'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { HelmetProvider } from 'react-helmet-async'

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://e92288938f374b10bd5edaafcebd893e@o1340938.ingest.sentry.io/6613868',
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
        environment: process.env.NODE_ENV,
    })

    mixpanel.init('3df00b9902a0ac4590422480ca4d7618', {
        debug: process.env.NODE_ENV !== 'production',
    })
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
      <HelmetProvider>
          <RecoilRoot>
            <App />
          </RecoilRoot>
      </HelmetProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
