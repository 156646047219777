import { useState } from 'react';

export default function Background () {
    const [loading, setLoading] = useState(true);
    const onLoad = () => {
        setLoading(false);
    }
    return <div className="background-image-container">
        <img
            src="https://unsplash.it/1200/900?random"
            onLoad={onLoad}
        />
        <div className={`background-backdrop${loading ? ' is-loading' : ''}`} />
    </div>
}
