import { useRecoilState } from 'recoil'
import { useEffect } from 'react'
import { getNextDayInMs, nowState } from './store'
import { dayjs } from './utils'
import Home from './Home'
import Background from './Background'

export default function App() {
    const [now, setNow] = useRecoilState(nowState)

    const onNextDay = () => {
        setNow(() => {
            const newNow = new Date()
            scheduleNextDay(newNow)
            return newNow
        })
    }

    const scheduleNextDay = (ms) => {
        const nextDay = getNextDayInMs(dayjs(ms))
        return setTimeout(onNextDay, nextDay - ms + 1)
    }

    useEffect(() => {
        const timeout = scheduleNextDay(now)
        return () => { clearTimeout(timeout) }
    })

    return (<div className="app-container">
        <Background />
        <Home />
    </div>)
}
