import confetti from 'canvas-confetti'
import dayjsLib from 'dayjs'
import relativeTimePlugin from 'dayjs/plugin/relativeTime'

export function makeConfetti () {
    confetti({
        particleCount: 100,
        startVelocity: 30,
        spread: 360,
        origin: {
            x: Math.random(),
            y: Math.random() - 0.2,
        }
    })
}

dayjsLib.extend(relativeTimePlugin)

export const dayjs = dayjsLib

export function convertImageToData (blob) {
    return new Promise (resolve => {
        const reader = new FileReader()
        reader.onloadend = () => { resolve(reader.result) }
        reader.readAsDataURL(blob)
    })
}