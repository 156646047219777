import { useRef, useEffect } from 'react'
import FlipDown from '@snapboard/flipdown'

export default function CountDown ({ timeDiff, onFinished }) {
    const countDownRef = useRef(null)

    useEffect(() => {
        if (countDownRef.current.children.length === 0) {
            const flipdown = new FlipDown(
                timeDiff,
                countDownRef.current.id, { theme: 'friday'}
            ).start()

            if (typeof onFinished === 'function') {
                flipdown.ifEnded(onFinished)
            }
        }
    })

    return <div
        id="countdown"
        className="flipdown mt-half"
        ref={countDownRef}
    />
}