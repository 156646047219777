import Dexie from 'dexie'

const db = new Dexie('itsfriday_db')

db.version(1).stores({
    profile: 'id,background_image,expired_at'
})

db.version(2).stores({
    profile: 'id,value,expired_at',
    profiles: 'id,value,expired_at',
}).upgrade(async tx => {
    await tx.profile.toCollection().modify(profile => {
        profile.value = profile.background_image
        delete profile.background_image
        return profile
    })

    const profiles = await tx.profile.toArray()
    await tx.profiles.bulkPut(profiles)

    return tx
})

db.version(3).stores({
    profile: null,
    profiles: 'id,value,expired_at',
})

db.version(4).stores({
    profiles: 'id,value,expiring_at',
}).upgrade(tx =>
    tx.profiles.toCollection().modify(profile => {
        profile.expiring_at = profile.expired_at
        delete profile.expired_at
        return profile
    })
)

export default db
