import { makeConfetti } from './utils'
import { useEffect } from 'react'
import { useRecoilValue} from 'recoil'
import { fridayState, /* , homeProfileQuery */} from './store'
import mixpanel from 'mixpanel-browser'
import CountDown from './CountDown'
import debounce from 'lodash.debounce'

const extras = ['', 'very close', 'very soon']

export default function Home ()  {
    const fridayInfo = useRecoilValue(fridayState)
    const extra = extras[fridayInfo.daysToFriday] ?? ''

    const onCelebrate = debounce(() => {
        if (process.env.NODE_ENV === 'production') {
            mixpanel.track('Celebrate')
        }
        makeConfetti()
    }, 100)

    useEffect(() => {
        if (fridayInfo.isFriday) {
            setTimeout(makeConfetti, 500)
        }
    }, [fridayInfo])

    return <div className="content">
            <h1>{fridayInfo.isFriday ? 'Yes! It\'s Friday!' : 'Is It Friday Yet?'}</h1>
            {fridayInfo.isFriday ?
                <button
                    className="button"
                    type="button"
                    onClick={onCelebrate}
                >
                    Push to Celebrate!
                </button> :
                <>{fridayInfo.diff === -1 ? <p>Nah, you just missed it!</p> : <p>
                    Nay, but
                    {extra !== '' ? ` it's ${extra}, it's only in` : ' it\'s coming in'}
                </p>}
                    <CountDown timeDiff={fridayInfo.friday.unix()}
                               onFinished={makeConfetti} />
                </>
            }
        </div>
}