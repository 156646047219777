import { atom, selector } from 'recoil'
import { dayjs } from '../utils'
import db from './idb'

const MAIN_PROFILE_INDEX = 0
const FRIDAY = 5

function getFridayInfo (now) {
    const diff = FRIDAY - now.day()
    const daysToFriday = diff < 0 ? Math.abs(diff) + 5 : diff
    const isFriday = daysToFriday === 0
    const friday = now.add(daysToFriday, 'd')
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .subtract(1, 's')

    return { isFriday, diff, daysToFriday, friday }
}

export function getNextDayInMs (now) {
    return now.add(1, 'd')
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .valueOf()
}

export const nowState = atom({
    key: 'NowState',
    default: new Date(),
})

export const nowDayjsSelector = selector({
    key: 'NowDayjsSelector',
    get: ({ get }) => {
        const now = get(nowState)
        return dayjs(now)
    }
})

export const fridayState = selector({
    key: 'FridayInfoSelector',
    get: ({ get }) => {
        const now = get(nowDayjsSelector)
        return getFridayInfo(now)
    },
})
